import * as React from 'react';

/**
 * The Settings panel for the right sidebar
 */
const SettingsPanel: React.FC = () => {
  return <p>Coming soon</p>;
};

export default SettingsPanel;
