import * as React from 'react';

/**
 * The Stats panel for the right sidebar
 */
const StatsPanel: React.FC = () => {
  return <p>Coming soon</p>;
};

export default StatsPanel;
