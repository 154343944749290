import * as React from 'react';

/**
 * The Comments panel for the right sidebar
 */
const CommentsPanel: React.FC = () => {
  return <p>Coming soon</p>;
};

export default CommentsPanel;
